<template>
	<div class="dash">
		<div class="title">{{ $t('dashboard.title') }}</div>
		<div class="body">
			<div class="card">
				<div class="item">
					<div class="top">
						<div class="name">{{ $t('dashboard.name1') }}💲</div>
						<div class="icon">
							<img src="../../assets/dash/card1.png" alt="" />
						</div>
					</div>
					<div class="bottom bottom1">
						<div>
							<span :class="locale == 'en' ? 'eng_span' : ''">{{ infoList.buyRate || 0 }}</span>
							%{{ $t('dashboard.body1_1') }}
						</div>
						<div class="">
							<span :class="locale == 'en' ? 'eng_span' : ''">
								{{ infoList.rechargeRate || 0 }}
							</span>
							%{{ $t('dashboard.body1_2') }}
						</div>
					</div>
				</div>
				<div class="item">
					<div class="top">
						<div class="name">{{ $t('dashboard.name2') }}💲</div>
						<div class="icon">
							<img src="../../assets/dash/card2.png" alt="" />
						</div>
					</div>
					<div class="bottom">
						<div class="bottom_box">
							<span>
								{{ infoList.commission || 0 }}
							</span>
							USD
						</div>
					</div>
				</div>
				<div class="item">
					<div class="top">
						<div class="name">{{ $t('dashboard.name3') }}</div>
						<div class="icon">
							<img src="../../assets/dash/card3.png" alt="" />
						</div>
					</div>
					<div class="bottom">
						<div class="bottom_box">
							<span>
								{{ infoList.inviteUserAmount || 0 }}
							</span>
							人
						</div>
					</div>
				</div>
				<div class="item">
					<div class="top">
						<div class="name">{{ $t('dashboard.name4') }}</div>
						<div class="icon">
							<img src="../../assets/dash/card4.png" alt="" />
						</div>
					</div>
					<div class="bottom">
						<div class="bottom_box">
							<span>
								{{ infoList.inviteUserCard || 0 }}
							</span>
							人
						</div>
					</div>
				</div>
			</div>
			<div class="copy">
				<div class="c_left">
					<div class="title1">{{ $t('dashboard.left_title1') }}</div>
					<div class="title2">{{ $t('dashboard.left_title2') }}</div>
					<img src="../../assets/dash/copy_left.png" alt="" class="img" />
				</div>
				<div class="c_right">
					<div class="c_title">{{ $t('dashboard.right_title1') }}</div>
					<div class="input" @click="copyToClipboard(infoList.inviteCode)">
						<input type="text" v-model="infoList.inviteCode" />
						<img src="../../assets/dash/copy.png" alt="" />
					</div>
					<div class="c_title">{{ $t('dashboard.right_title2') }}</div>
					<div class="input" @click="copyToClipboard(infoList.inviteUrl)">
						<input type="text" v-model="infoList.inviteUrl" />
						<img src="../../assets/dash/copy.png" alt="" />
					</div>
					<div class="button" @click="open">{{ $t('dashboard.button') }}</div>
				</div>
			</div>
			<div class="history">
				<div class="left item">
					<div class="h_title">
						<div class="icon"></div>
						<span>{{ $t('dashboard.history') }}</span>
					</div>
					<div class="h_body">
						<div class="empty" v-if="!infoList.inviteHistoryList">{{ $t('dashboard.empty') }}</div>
						<div class="content" v-else>
							<div class="h_item" v-for="(item, index) in infoList.inviteHistoryList" :key="index">
								<div class="user">{{ item.email }}</div>
								<div class="date">{{ item.date }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right item">
					<div class="h_title">
						<div class="icon"></div>
						<span>{{ $t('dashboard.history2') }}</span>
					</div>
					<div class="h_body">
						<div class="empty" v-if="!infoList.inviteCommisionList">
							{{ $t('dashboard.empty') }}
						</div>
						<div class="content" v-else>
							<div
								class="h_item"
								v-for="(item, index) in infoList.inviteCommisionList"
								:key="index"
							>
								<div class="user">{{ item.email }}</div>
								<div class="user">{{ item.amount }}</div>
								<div class="date">{{ item.date }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog v-model="state.dialogTableVisible" :title="$t('dashboard.dia')" width="300">
			<div class="qr_box">
				<qrcode-vue :value="infoList.inviteUrl" :size="200" style="margin: 0 auto" />
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import { inviteList } from '@/api/index';

const { t, locale } = useI18n();
let state = reactive({
	dialogTableVisible: false,
});

let infoList = ref({});

// api 请求
inviteList()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			infoList.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
// 点击复制到剪贴板    (兼容ios)
const copyToClipboard = (content) => {
	let oInput = document.createElement('input');
	oInput.value = content;
	document.body.appendChild(oInput);
	oInput.select(); // 选择oInput中所有文本对象
	if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
		//兼容ios
		if (!document.execCommand('Copy')) {
			oInput.setSelectionRange(0, oInput.value.length);
		}
	}
	document.execCommand('Copy'); // 执行浏览器复制命令
	document.body.removeChild(oInput);
	return new Promise((resolve, reject) => {
		if (document.execCommand('Copy')) {
			resolve(content);
			ElMessage.success(t('dashboard.message1'));
		} else {
			reject(content);
			ElMessage.error(t('dashboard.message2'));
		}
	});
};
const open = () => {
	state.dialogTableVisible = true;
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.dash {
		width: 100%;
		width: 1230px;
		width: 70vw;
		min-height: 1288px;
		background: #ffffff;
		border-radius: 7px;
		border: 1px solid #e4e4e4;
		margin: 0 0 0 15px;

		.title {
			border-bottom: 1px solid #e4e4e4;
			font-size: 25px;
			color: #333333;
			font-weight: 600;
			height: 72px;
			padding-left: 25px;
			display: flex;
			align-items: center;
		}

		.body {
			padding: 42px 25px 38px;
			.card {
				display: flex;
				justify-content: space-between;
				.item {
					width: 290px;
					height: 170px;
					background: #f0faf6;
					border-radius: 7px;
					display: flex;
					flex-direction: column;
					padding: 0 20px 30px;
					.top {
						display: flex;
						justify-content: space-between;
						font-size: 17px;
						color: #333333;
						align-items: center;
						.icon {
							img {
								width: 80px;
							}
						}
					}
					.bottom {
						width: 100%;
						display: flex;
						justify-content: space-between;
						.bottom_box {
							span {
								font-size: 36px;
								color: #333333;
								font-weight: 600;
							}
						}
					}
					.bottom1 {
						flex-direction: column;
						span {
							font-size: 30px;
							color: #333333;
							font-weight: 600;
						}
						.eng_span {
							display: inline-block;
							font-size: 20px;
							margin-bottom: 4px;
						}
					}
				}
			}
			.copy {
				margin-top: 30px;
				width: 100%;
				height: 554px;
				background: linear-gradient(
					180deg,
					rgba(244, 253, 251, 0.83) 0%,
					#ebfdf8 63%,
					#e1fbf4 100%
				);
				border-radius: 7px;
				padding: 78px 78px;
				display: flex;
				justify-content: space-between;
				.c_left {
					width: 360px;
					display: flex;
					flex-direction: column;
					align-items: center;
					.title1 {
						font-size: 40px;
						color: #333333;
						margin-bottom: 12px;
					}
					.title2 {
						font-size: 20px;
						color: #666666;
						margin-bottom: 16px;
					}
					.img {
						width: 324px;
					}
				}
				.c_right {
					width: 660px;
					height: 398px;
					background: #ffffff;
					border-radius: 7px;
					padding: 30px 25px;
					.c_title {
						font-size: 22px;
						color: #333333;
						margin-bottom: 12px;
					}
					.input {
						cursor: pointer;
						width: 610px;
						height: 62px;
						background: #f6f8fc;
						border-radius: 5px;
						font-size: 22px;
						color: #333333;
						font-weight: 600;
						margin-bottom: 27px;
						display: flex;
						input {
							cursor: pointer;
							background: #f6f8fc;
							width: 548px;
							border: 0;
							height: 100%;
							padding-left: 20px;
						}
						img {
							width: 62px;
						}
					}
					.button {
						margin-top: 36px;
						width: 100%;
						height: 62px;
						background: #19d079;
						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 22px;
						color: #ffffff;
					}
				}
			}
			.history {
				margin-top: 30px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				.item {
					width: 48%;
					height: 400px;
					border-radius: 7px;
					border: 1px solid #e4e4e4;
					.h_title {
						padding: 25px;
						width: 100%;
						height: 84px;
						display: flex;
						align-items: center;
						.icon {
							margin-right: 12px;
							width: 7px;
							height: 22px;
							background: #19d079;
						}
					}
					.h_body {
						display: flex;
						width: 100%;
						.h_title {
							font-weight: 600;
							font-size: 25px;
							color: #333333;
						}
						.empty {
							width: 100%;
							height: 300px;
							display: flex;
							justify-content: center;
							margin-top: 58px;
							font-size: 17px;
							color: #bbbbbb;
						}
						.content {
							width: 100%;
							height: 300px;
							padding: 0 25px;
							overflow: auto;
							.h_item {
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 100%;
								height: 50px;
								border-bottom: 1px solid #e4e4e4;
							}
							.h_item:last-child {
								border: 0;
							}
						}
					}
				}
			}
		}
		.qr_box {
			width: 100%;
			height: 100%;
			padding: 30px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f6f8fc;
			border-radius: 8px;
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss"></style>
